import { useState, useEffect } from 'react'

import { Public } from '../../../../business'
import { payperMagic } from '../../../../thirdPartyIntegration'
import { CircularProgress } from '../../../components'
import useAccess from './useAccess'
import Modal from './Modal'
import View from './View'

const Access = () => {
  const { unauthenticationIsConfirmedInLoad, handleCloseButton, ...viewProps } =
    useAccess()

  const [promotion, setPromotion] = useState()

  useEffect(() => {
    ;(async () => {
      const magicPromotion = payperMagic.getPromotionInfo()
      if (magicPromotion) {
        const partner = await Public.getPartnerById(magicPromotion.partner_id)
        if (partner) {
          setPromotion({
            partnerName: partner.name,
            durationMin: magicPromotion.duration_minutes,
          })
        }
      }
    })()
  }, [])

  if (!Boolean(unauthenticationIsConfirmedInLoad))
    return <CircularProgress height='80vh' />

  return (
    <Modal onCancel={handleCloseButton}>
      <View {...viewProps} promotion={promotion} />
    </Modal>
  )
}

export default Access
