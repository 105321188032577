const verticalPadding = 0.8

const styles = {
  root: {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
    backgroundColor: 'primary.main',
    color: 'neutral.700',
    height: 'auto',
    py: (theme) => theme.spacing(verticalPadding),
    '& button': {
      height: (theme) => theme.spacing(3),
      p: 0,
    },

    '& svg, span': {
      color: 'neutral.700',
    },
    '& .MuiBottomNavigationAction-label': {
      mt: (theme) => theme.spacing(0.3),
    },
    '& .Mui-selected': {
      fontWeight: 500,
    },
  },

  xtraMarginBottom: {
    pb: (theme) =>
      `calc(${theme.spacing(
        verticalPadding - 0.75,
      )} + env(Safe-area-inset-bottom, 28px))`,
  },
}

export default styles
