import theme from '../../../theme'

const styles = {
  notAuthenticated: {
    '& > .MuiBox-root': {
      pt: {
        xs: 5.5,
        md: 6.5,
        lg: 8,
      },
    },
  },
  isAuthenticated: {
    '& > .MuiBox-root': {
      pt: {
        xs: 2.5,
        md: 3.5,
        lg: 4.5,
      },
    },
  },
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: (theme) => `min(100%, ${theme.spacing(28.75)})`,
    px: {
      xs: 1.25,
      md: 3,
    },
    pb: 3,
    textAlign: 'left',
  },
  title: {
    ...theme.typography.h4,
    mb: 1,
    color: 'neutral.700',
  },
  text: {
    ...theme.typography.body2,
    mb: 2,
    color: 'neutral.500',
  },
  textItem: {
    mb: 1,
  },
  buttons: {
    mb: {
      xs: 3,
      md: 4.5,
    },
  },
  secondaryButton: {
    mt: 0.5,
  },
  wrapImage: {
    alignSelf: 'center',
  },
}

export default styles
