import PropTypes from 'prop-types'
import { Box, IconButton, Typography, Dialog } from '@mui/material'

import { X } from '../../../../styleguide/icons'
import { useTexts } from '../../../../texts'
import { ReactComponent as PayperLogo } from '../../../components/PayperBanner/PayperLogo.svg'

import styles from './access.styles.js'

const modalPropTypes = {
  background: PropTypes.oneOf(['solid', 'gradient', 'dark']),
  onCancel: PropTypes.func,
  children: PropTypes.node,
}

const Modal = ({ background = 'solid', onCancel, children }) => {
  const texts = useTexts()

  return (
    <Dialog open>
      <Box id='modal' sx={{ ...styles.root, ...styles[background] }}>
        <Box id='header' sx={styles.header}>
          <Box sx={styles.logo}>
            <PayperLogo aria-label='Payper logo' color='#121212' />
            <Typography variant='body2' sx={styles.text}>
              {texts.payperSlogan()}
            </Typography>
          </Box>
          {Boolean(onCancel) && (
            <Box sx={styles.closeButton}>
              <IconButton
                aria-label={texts.getCloseAction()}
                onClick={onCancel}
              >
                <X />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box id='content' sx={styles.content}>
          {children}
        </Box>
      </Box>
    </Dialog>
  )
}

Modal.propTypes = modalPropTypes

export default Modal
