import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import { Session } from '../../../../business/index.js'
import { Logout } from '../../../../styleguide/icons/index.js'
import { useTexts } from '../../../../texts/index.js'
import { PersonalDataCard } from '../../../components/index.js'

const styles = {
  root: {
    width: (theme) => `min(100%, ${theme.spacing(19.5)})`,
    mx: 'auto',
  },
  actions: {
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1,
  },
}

const userPersonalDataPropTypes = {
  userData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    familyName: PropTypes.string,
    phone: PropTypes.string,
    communicationsAccepted: PropTypes.bool,
  }),
  onClickUpdatePassword: PropTypes.func,
  onClickUpdatePersonalData: PropTypes.func,
  onClickLogout: PropTypes.func,
}

const PersonalData = ({
  userData,
  onClickUpdatePassword,
  onClickUpdatePersonalData,
  onClickLogout,
}) => {
  const texts = useTexts()

  return (
    <Box sx={styles.root}>
      <PersonalDataCard
        userData={userData}
        onClickUpdatePersonalData={onClickUpdatePersonalData}
      />

      <Box sx={styles.actions}>
        {!Session.getIsIdpAccount() && (
          <Button
            id='change-email-btn'
            size='small'
            variant='outlined'
            onClick={onClickUpdatePassword}
            fullWidth
          >
            {texts.updatePassword()}
          </Button>
        )}
        <Button
          id='logout'
          size='small'
          color='primary'
          onClick={onClickLogout}
          startIcon={<Logout />}
          fullWidth
        >
          {texts.getUserLogoutAction()}
        </Button>
      </Box>
    </Box>
  )
}

PersonalData.propTypes = userPersonalDataPropTypes

export default PersonalData
