import { Box, Typography } from '@mui/material'

import { useTexts } from '../../../texts'
import { ReactComponent as PayperLogo } from './PayperLogo.svg'

const styles = {
  root: {
    backgroundColor: 'neutral.200',
    minHeight: (theme) => theme.spacing(7.625),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 0.7,
    py: { xs: 0, md: 2.5 },
  },
}

const PayperBanner = () => {
  const texts = useTexts()
  return (
    <Box sx={styles.root}>
      <PayperLogo aria-label='Payper logo' color='#121212' />
      <Typography variant='body2'>{texts.payperSlogan()}</Typography>
    </Box>
  )
}

export default PayperBanner
