import { useNavigate } from 'react-router-dom'
import {
  TextField,
  InputAdornment,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  IconButton,
} from '@mui/material'

import EVENTS_NAMES from '../../../../analytics/eventsNames.json'
import { useTexts } from '../../../../texts'
import { Search as SearchIcon } from '../../../../styleguide/icons'
import articleCardStyles from '../../../components/ArticleCard/articleCard.styles'

const styles = {
  root: {
    px: articleCardStyles.card.px,
  },
  searchBar: {
    width: (theme) => `min(100%, ${theme.spacing(60)})`,
    mx: 'auto',
    mt: {
      xs: 0,
      md: 1,
    },

    display: 'flex',
    columnGap: 1,
    '& input': {
      flexGrow: 2,
      py: 0.75,
    },
    '& button': {
      width: (theme) => ({ md: theme.spacing(11.875) }),
    },
  },
}

const Search = () => {
  const texts = useTexts()
  const navigate = useNavigate()
  const theme = useTheme()
  const widerThanTablet = useMediaQuery(theme.breakpoints.up('md'))

  const navigateToSearch = (ev) => {
    ev.preventDefault()
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PD_SEARCH,
          text: ev.target[0].value,
        },
      }),
    )

    navigate('search', { state: { searchValue: ev.target[0].value } })
  }

  return (
    <Box sx={styles.root}>
      <Box component='form' onSubmit={navigateToSearch} sx={styles.searchBar}>
        <TextField
          id='search'
          name='search'
          fullWidth
          placeholder={texts.searchPlaceholder()}
          InputProps={{
            startAdornment: widerThanTablet && (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {widerThanTablet ? (
          <Button size='small' color='primary' type='submit'>
            {texts.searchAction()}
          </Button>
        ) : (
          <IconButton aria-label='buscar' type='submit'>
            <SearchIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default Search
