import { useState } from 'react'
import { Box, Typography } from '@mui/material'

import { Coins } from '../../../../../../styleguide/icons'
import { User } from '../../../../../../business'
import EVENTS_NAMES from '../../../../../../analytics/eventsNames.json'
import { useTexts } from '../../../../../../texts'
import { formatNumber } from '../../../../../format'
import {
  BankCardRegistration,
  CircularProgress,
} from '../../../../../components'

import styles from './bankCardStepWidget.styles'

const BankCardStepWidget = ({ sub, articlePrice, onContinue }) => {
  const texts = useTexts()
  const [isUpdatingBalance, setIsUpdatingBalance] = useState(false)

  const handleSuccessBankCardRegistration = async () => {
    setIsUpdatingBalance(true)
    await User.updateAccountBalance(articlePrice)

    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PM_FORM_SUBMIT_REGISTER_BANKCARD,
          status: 'success',
        },
      }),
    )

    onContinue()
  }

  const handleDispatchEventOnError = (errorMessage) => {
    document.dispatchEvent(
      new CustomEvent('payperAnalyticsPrivateEvent', {
        detail: {
          name: EVENTS_NAMES.PM_FORM_SUBMIT_REGISTER_BANKCARD,
          status: 'error',
          formError: errorMessage,
        },
      }),
    )
  }

  return (
    <Box sx={styles.root}>
      <Box sx={styles.price}>
        <Typography variant='body3'>
          {texts.getIntroBankCardPriceLabel()}
        </Typography>
        <Typography sx={styles.priceAmount}>
          {formatNumber(articlePrice)} <span>€</span>
        </Typography>
      </Box>
      <Box sx={styles.bg}>
        <Box sx={styles.description}>
          <Typography component='h1' variant='h5' sx={styles.descriptionTitle}>
            {texts.getIntroBankCardDataLabel()}
          </Typography>
          <Typography variant='body2' sx={styles.descriptionText}>
            {texts.getIntroBankCardDataCaption()}
          </Typography>
        </Box>
        {!Boolean(articlePrice) || isUpdatingBalance ? (
          <CircularProgress />
        ) : (
          <BankCardRegistration
            onSuccess={handleSuccessBankCardRegistration}
            onError={handleDispatchEventOnError}
            sub={sub}
            customCtaConfig={{
              icon: <Coins />,
              iconStyles: { left: '10%' },
              buttonStyles: 'padding-right: 18%',
              label: texts.getIntroBankCardCTA(formatNumber(articlePrice)),
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default BankCardStepWidget
