import { useState } from 'react'
import { Box, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import EVENTS_NAMES from '../../../../../../analytics/eventsNames.json'
import { User } from '../../../../../../business'
import { useTexts } from '../../../../../../texts'
import { TopUpSelectorCards, Alert } from '../../../../../components'
import { Coins, Redsys } from '../../../../../../styleguide/icons'
import styles from './topUpStepWidget.styles'

const AMOUNT_OPTIONS = [1, 5, 10]
const POPULAR_OPTION = AMOUNT_OPTIONS[1]
const DEFAULT_OPTION = AMOUNT_OPTIONS[0]

const TopUpStepWidget = ({ onContinue, onError }) => {
  const texts = useTexts()

  const [selected, setSelected] = useState(DEFAULT_OPTION)
  const [isRequestingUpdate, setIsRequestingUpdate] = useState(false)
  const [customError, setCustomError] = useState('')

  const updateBalance = async (amount) => {
    setIsRequestingUpdate(true)
    try {
      await User.updateAccountBalance(amount)

      document.dispatchEvent(
        new CustomEvent('payperAnalyticsPrivateEvent', {
          detail: {
            name: EVENTS_NAMES.PM_FORM_SUBMIT_TOPUP,
            status: 'success',
            amount,
          },
        }),
      )

      onContinue()
    } catch (error) {
      let errorMessage = texts.getUnexpectedError()
      let isErrorShowedOnView = false

      if (error instanceof User.RedsysError) {
        const redsysErrorMessage = User.getRedsysDSResponseMessageTranslation(
          error.message,
        )
        if (Boolean(redsysErrorMessage)) errorMessage = redsysErrorMessage

        setCustomError(errorMessage)
        isErrorShowedOnView = true
      }

      document.dispatchEvent(
        new CustomEvent('payperAnalyticsPrivateEvent', {
          detail: {
            name: EVENTS_NAMES.PM_FORM_SUBMIT_TOPUP,
            status: 'error',
            formError: errorMessage,
            amount,
          },
        }),
      )

      onError({ isErrorShowedOnView })
    }
    setIsRequestingUpdate(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await updateBalance(selected)
  }

  return (
    <Box sx={styles.root}>
      <Box
        component='form'
        sx={styles.form}
        id='amount'
        onSubmit={handleSubmit}
      >
        <TopUpSelectorCards
          options={AMOUNT_OPTIONS}
          popularOption={POPULAR_OPTION}
          selected={selected}
          setSelected={setSelected}
        />
        <Box sx={styles.actions}>
          {Boolean(customError) && (
            <Alert variant='error' withContactLink text={customError} />
          )}

          <LoadingButton
            id='register-top-up-btn'
            color='secondary'
            startIcon={<Coins />}
            loading={isRequestingUpdate}
            type='submit'
            form='amount'
            fullWidth
            // disabled={errorOnRequest && !isRequestingUpdate}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: texts.getTopUpConfirmAction(selected),
              }}
            />
          </LoadingButton>
          <Button
            id='register-skip-top-up-btn'
            size='small'
            fullWidth
            onClick={onContinue}
          >
            {texts.getTopUpSkipAction()}
          </Button>
        </Box>
      </Box>
      <Box sx={styles.logos}>
        <Redsys />
      </Box>
    </Box>
  )
}

export default TopUpStepWidget
