import articleCardStyles from '../../../components/ArticleCard/articleCard.styles'

const styles = {
  root: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    willChange: { xs: 'transform', md: 'auto' },
  },
  fixed: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: 'neutral.100',
  },
  searchBar: {
    px: articleCardStyles.card.px,

    width: (theme) => `min(100%, ${theme.spacing(60)})`,
    mx: 'auto',
    my: {
      xs: 1,
      md: 3,
    },

    display: 'flex',
    alignItems: 'center',
    columnGap: 1,
    '& input': {
      flexGrow: 2,
      py: 0.75,
    },
    '& button[type="button"]': {
      height: 'fit-content',
    },
    '& button[type="submit"]': {
      width: (theme) => ({ md: theme.spacing(11.875) }),
    },
  },
  results: {
    flexGrow: 2,
  },
}

export default styles
